<template>
  <v-app>
    <v-main class="login-bg" v-if="!isLoading">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "LayoutPublicFreelancer",
  data: () => ({
    isLoading: true
  }),
  async mounted() {
    this.isLoading = true;
    let programKey = this.$sonyProgram.getProgramKeyByDomain();
    await this.$api
      .get("/api/programs/byKey/" + programKey + "/public")
      .then(({ data }) => {
        let program = data;
        return Promise.all([
          this.$auth.storeSelectProgram(program),
          this.$auth.storeSelectClient(program.programGroup.client)
        ]);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
};
</script>
